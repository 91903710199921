<template>
  <div class="filtro">
    <h1>Filtro - UOL</h1>
    <!--    <div class="filtro_content">
      <div class="grafico">
        <div id="chart">
          <apexchart
            type="bar"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
    </div> -->
    <div class="tableCsv">
      <div class="titleImportacao">
        <span>Resultado</span>
      </div>
      <div class="menuDataSelect d-flex">
        <div class="dataStyle">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                prepend-inner-icon="mdi-calendar"
                rounded
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pt-br"
              range
              v-model="date"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div class="selectStyle">
          <v-select
            :items="items"
            label="Escolha um relatório"
            rounded
            outlined
            prepend-inner-icon="mdi-chart-bell-curve-cumulative"
            v-model="tipoFiltro"
          ></v-select>
        </div>
        <v-btn
          class="mt-1"
          color="#1457ed"
          dark
          large
          rounded
          @click="atualizarDados()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          class="mt-1"
          color="green"
          dark
          large
          rounded
          @click="csvExport(csvData)"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </div>
      <img
        src="https://pagseguro.grupocobz.com.br/img/filter.svg"
        width="250px"
        alt="Filtro"
        class="imgFilter"
        v-show="acordos == null"
      />
      <v-simple-table v-show="tipoFiltro === 'contra-proposta'">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center fontSizeTh">Contrato</th>
              <th class="text-center fontSizeTh">CPF</th>
              <th class="text-center fontSizeTh">Date</th>
              <th class="text-center fontSizeTh">Email</th>
              <th class="text-center fontSizeTh">Método de envio</th>
              <th class="text-center fontSizeTh">Nome</th>
              <th class="text-center fontSizeTh">Resumo</th>
              <th class="text-center fontSizeTh">Telefone</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="acordo in acordos" :key="acordo.id">
              <td class="text-center fontSizeTable">{{ acordo.contrato }}</td>
              <td class="text-center">{{ acordo.cpf }}</td>
              <td class="text-center">{{ acordo.data }}</td>
              <td class="text-center">{{ acordo.email }}</td>
              <td class="text-center">{{ acordo.metodo_envio }}</td>
              <td class="text-center">{{ acordo.nome }}</td>
              <td class="text-center">{{ acordo.resumo_da_proposta }}</td>
              <td class="text-center">{{ acordo.telefone }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-simple-table v-show="tipoFiltro === 'acordo'">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center fontSizeTh">Nome</th>
              <th class="text-center fontSizeTh">Contrato</th>
              <th class="text-center fontSizeTh">Emissão</th>
              <th class="text-center fontSizeTh">Valor Total</th>
              <th class="text-center fontSizeTh">Entrada</th>
              <th class="text-center fontSizeTh">Resumo</th>
              <th class="text-center fontSizeTh">Método de envio</th>
              <th class="text-center fontSizeTh">Email</th>
              <th class="text-center fontSizeTh">Telefone</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="acordo in acordos" :key="acordo.id">
              <td class="text-center fontSizeTable">{{ acordo.nome }}</td>
              <td class="text-center">{{ acordo.contrato }}</td>
              <td class="text-center">{{ acordo.emissao }}</td>
              <td class="text-center">R$ {{ acordo.valor_total }}</td>
              <td class="text-center">R$ {{ acordo.entrada }}</td>
              <td class="text-center">{{ acordo.resumo_da_proposta }}</td>
              <td class="text-center">{{ acordo.metodo_envio }}</td>
              <td class="text-center">{{ acordo.email }}</td>
              <td class="text-center">{{ acordo.telefone }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-simple-table v-show="tipoFiltro === 'desistencia'">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center fontSizeTh">Nome</th>
              <th class="text-center fontSizeTh">CPF</th>
              <th class="text-center fontSizeTh">Cidade</th>
              <th class="text-center fontSizeTh">Data de nascimento</th>
              <th class="text-center fontSizeTh">Email</th>
              <th class="text-center fontSizeTh">Telefone</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="acordo in acordos" :key="acordo.id">
              <td class="text-center fontSizeTable">{{ acordo.nome }}</td>
              <td class="text-center">{{ acordo.cpf }}</td>
              <td class="text-center">{{ acordo.cidade }}</td>
              <td class="text-center">{{ acordo.data_nasc }}</td>
              <td class="text-center">{{ acordo.email }}</td>
              <td class="text-center">{{ acordo.telefone }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Filtro",
  components: { apexchart: VueApexCharts },
  data() {
    return {
      date: ["", ""],
      tipoFiltro: null,
      menu: false,
      labelDate: null,
      acordos: null,
      items: ["Acordo fechado", "Desistência", "Contra-Proposta"],
    };
  },
  created() {},
  methods: {
    atualizarDados() {
      /*  const max = 90;
      const min = 20;
      const newdata = this.series[0].data.map(() => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      });
      const newdata1 = this.series[0].data.map(() => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      });
      const newdata2 = this.series[0].data.map(() => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      });
      this.series = [
        {
          data: newdata,
        },
        {
          data: newdata1,
        },
        {
          data: newdata2,
        },
      ]; */
      let jwt = localStorage.getItem("jwt");
      if (this.tipoFiltro === "Acordo fechado") {
        this.tipoFiltro = "acordo";
      } else if (this.tipoFiltro === "Desistência") {
        this.tipoFiltro = "desistencia";
      } else {
        this.tipoFiltro = "contra-proposta";
      }
      fetch(`${this.$url}/dashboard/filtros`, {
        method: "POST",
        body: JSON.stringify({
          jwt: jwt,
          data1: this.date[0],
          data2: this.date[1],
          tipoFiltro: this.tipoFiltro,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          this.acordos = json;
        });
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
  },

  computed: {
    dateRangeText() {
      /* if (this.date[0] == "") {
        this.labelDate = "Escolha uma data";
      } else {
        return this.date.join(" - ");
      } */
      return this.date.join(" - ");
    },
    csvData() {
      return this.acordos.map((item) => ({
        ...item,
      }));
    },
  },
};
</script>

<style scoped>
.filtro {
  height: 100%;
  padding: 30px 20px;
}
.filtro_content {
  background-color: white;
  padding: 16px 8px 16px 8px;
  border-radius: 8px;
}
.menuDataSelect {
  width: 100%;
  justify-content: center;

  gap: 40px;
}
h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #2d2f50;
}
.dataStyle {
  width: 250px;
}
.selectStyle {
  width: 290px;
}
.grafico {
  width: 100%;
}

.tableCsv {
  width: 100%;
  margin-top: 30px;
  background-color: white;
  border-radius: 8px;
}

.imgFilter {
  margin: 0 auto;
  display: block;
  padding-bottom: 32px;
  opacity: 0.7;
}

.titleImportacao {
  background-color: #1457ed;
  border-radius: 8px 8px 0px 0px;
  padding: 8px;
  text-align: center;
  margin-bottom: 16px;
}
.titleImportacao span {
  color: white;
  font-size: 22px;
}
</style>