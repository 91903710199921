<template>
  <LayoutAdmin>
    <Filtro />
  </LayoutAdmin>
</template>

<script>
import LayoutAdmin from "@/Layouts/LayoutAdmin.vue";
import Filtro from "@/components/Filtro.vue";
export default {
  name: "FiltroAdmin",
  components: { LayoutAdmin, Filtro },
};
</script>

<style scoped>
</style>